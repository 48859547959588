(() => {
  const handleHeaderMenu = () => {
    let menuOpenTrigger,
      bodyEl,
      headerEl,
      winWidth,
      headerDropdownLinks,
      hasDropdownOpen = false,
      headerHeight,
      subHeaderHeight = 60,
      stickyHeaderClass = 'header--sticky',
      isDesktopView = window.matchMedia('(min-width: 1024px)').matches;

    // closes mobile menu navigation
    // adds class to body to hide the overflow
    const menuCloseHandler = () => {
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'header__menu-mobile-trigger') {
          bodyEl.classList.toggle('u-hide-overflow-in-mobile');
        } else if (id === 'header__menu-mobile-close') {
          menuOpenTrigger?.click();
        }
      });
    };

    // removes all the active dropdowns
    const removeActiveDropdowns = () => {
      headerEl.querySelectorAll('.js-toggle-dropdown').forEach(elem => {
        elem.classList.remove('js-toggle-dropdown');
      });
    };

    const windowResizeHandler = () => {
      // needed to make sure that the window resize event is not triggered by the mobile URL bar hide/show
      // mobile URL bar hide/show only changes height, so checking for window width change
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (winWidth !== curWidth) {
          winWidth = curWidth;
          isDesktopView = winWidth >= 1024;
          headerHeight = headerEl.clientHeight + subHeaderHeight;
        }
      });
    };

    // when page is scrolled add a sticky class to the header element
    const stickyHeaderHandler = () => {
      window.addEventListener('scroll', () => {
        headerEl?.classList.toggle(stickyHeaderClass, window.scrollY > 0);
      });
    };

    // in desktop show dropdown menu on hover and when body is hovered hide the dropdown
    // in mobile show/hide dropdown menu on click
    const dropdownHandler = () => {
      headerDropdownLinks.forEach(el => {
        el.addEventListener('mouseover', () => {
          if (isDesktopView) {
            removeActiveDropdowns();
            headerHeight = headerEl.clientHeight + subHeaderHeight;
            el.classList.add('js-toggle-dropdown');
            hasDropdownOpen = true;
          }
        });

        bodyEl.addEventListener('mousemove', e => {
          if (e.clientY > headerHeight && hasDropdownOpen && isDesktopView) {
            removeActiveDropdowns();
          }
        });

        el.addEventListener('click', e => {
          if (isDesktopView !== true) {
            if (el.classList.contains('emu-navigation__item-parent')) {
              const target = e.target as HTMLElement;
              // only expand the menu if parent li is clicked, or if anchor is clicked, whose immediate parent is li
              // clicking on arrow is considered clicking on anchor
              // clicking on text inside anchor is considered clicking on span (.nav-item.parent), so the page redirects like we have in live
              if (
                (target?.nodeName?.toLowerCase() === 'li' &&
                  target?.classList.contains('emu-navigation__item-parent')) ||
                (target?.nodeName?.toLowerCase() === 'a' &&
                  target.parentElement?.classList.contains(
                    'emu-navigation__item-parent'
                  ))
              ) {
                e.preventDefault();

                if (el.classList.contains('js-toggle-dropdown')) {
                  el.classList.remove('js-toggle-dropdown');
                } else {
                  removeActiveDropdowns();
                  el.classList.toggle('js-toggle-dropdown');
                }
                hasDropdownOpen = el.classList.contains('js-toggle-dropdown');
              }
            }
          }
        });
      });
    };

    // initiating the variables used across this file
    const initVariables = () => {
      menuOpenTrigger = document.querySelector(
        '#' + 'header__menu-mobile-trigger'
      )! as HTMLElement;
      headerEl = document.querySelector('header.header') as HTMLElement;
      bodyEl = document.body;
      winWidth = window.innerWidth;
      headerDropdownLinks = headerEl?.querySelectorAll(
        'li.emu-navigation__item'
      );
      headerHeight = headerEl.clientHeight + subHeaderHeight;
    };

    // function calls
    initVariables();
    menuCloseHandler();
    windowResizeHandler();
    stickyHeaderHandler();
    dropdownHandler();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeaderMenu);
  } else {
    handleHeaderMenu();
  }
})();
