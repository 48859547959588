(() => {
  const addTnsLiveRegionText = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      carouseInstances.forEach(inst => {
        // @ts-ignore
        const curInfo = inst?.getInfo?.();
        const $liveRegion = curInfo?.container
          ?.closest?.('.carousel')
          ?.querySelector?.('.tns-liveregion') as HTMLElement;
        const updateLiveRegion = info => {
          try {
            const curIndex = info.displayIndex;
            const total = info.slideCount;
            $liveRegion.innerHTML =
              `<span>${curIndex}</span>` + `<span>/</span>` + total;
            $liveRegion.classList.add('can-show');
          } catch (e) {
            console.warn(e);
            $liveRegion.classList.remove('can-show');
          }
        };
        if ($liveRegion) {
          // @ts-ignore
          inst?.events?.on?.('transitionEnd', info => {
            updateLiveRegion(info);
          });
          updateLiveRegion(curInfo);
        }
      });
    }
  };

  const init = () => {
    addTnsLiveRegionText();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
