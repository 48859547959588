// Stylesheets
import './main.scss';

// import favicon here.
import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/**/*.ts';

import './menu';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}
(() => {
  // for all the anchors that has isi_indications as href, scroll the user tot he ISI
  const handleISILinks = () => {
    const isiLinks = document?.querySelectorAll("a[href='#isi_indications']");
    const isiBtn = document.querySelector('#' + 'isi-btn')! as HTMLElement;

    if (isiBtn) {
      isiLinks.forEach(isiLink => {
        isiLink?.addEventListener('click', e => {
          e.preventDefault();
          isiBtn.click();
        });
      });
    }
  };

  const init = () => {
    if (window.Bus) {
      // listen to events here
    }

    handleISILinks();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
